.tab {
  color: #797a7a;
  font-size: 18px;
  text-align: center;


  a {
    display: block;
    max-width: 253px;
    width: 100%;
    padding-bottom: 20px;
    font-weight: 400;

    &:hover {
      color: #0d4740;
    }

    &.active {
      border-bottom: 6px solid #0d4740;
      color: #0d4740;
      font-weight: 600;
      transition: border-bottom 275ms ease-in 275ms;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;

    a {
      padding-bottom: 12px;
    }
  }
}

.navLink {
  color: #0D4740;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  padding: 19.75px 38px;
  // padding: 19.75px 52px;
  white-space: nowrap;
  display: block;

  @media screen and (max-width: 768px) {
    padding: 19.75px 24px;
  }
}