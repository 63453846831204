.sidebar,
.mobileTab {
  font-family: 'PolySans';

  a {
    display: block;
    padding: 18px 59px;
  }

  a.active {
    background: #f2f4f3;
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;

    p {
      color: #0d4740;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 1179px) {
    a {
      padding: 14px 12px;
      max-width: 240px;
    }
  }
}

.mobileTab {
  @media screen and (max-width: 1179px) {
    a {
      padding: 8px 12px;
    }

    a.active {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border-bottom-left-radius: 0;
    }
  }
}