@import url('https://fonts.googleapis.com/css2?family=Poly&display=swap');

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

button,
input[type='button'],
input[type='submit'] {
  cursor: pointer;
}

input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 1;
  background: url('./assets/svg/calendar.svg') no-repeat;
  width: 19.5px;
  height: 19.5px;
}

// PostGrotesk
@font-face {
  font-family: 'PostGrotesk';
  src: url('./assets/fonts/Grotesk-700.otf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PostGrotesk';
  src: url('./assets/fonts/Grotesk-600.otf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PostGrotesk';
  src: url('./assets/fonts/Grotesk-500.otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PostGrotesk';
  src: url('./assets/fonts/Grotesk-400.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PostGrotesk';
  src: url('./assets/fonts/Grotesk-300.otf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PostGrotesk';
  src: url('./assets/fonts/Grotesk-200.otf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

// PolySans
@font-face {
  font-family: 'PolySans';
  src: url('./assets/fonts/PolySans-600.otf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PolySans';
  src: url('./assets/fonts/PolySans-500.otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PolySans';
  src: url('./assets/fonts/PolySans-400.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.editable {
  min-height: 80px;
}

.editable:focus-visible {
  outline-color: #efefef;

  list-style: disc !important;
}